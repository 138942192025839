import React from "react";
import { useNavigate } from "react-router-dom";
import { useLnaguage } from "../context/LnaguageContext";
import { Wrap } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Footer() {
  //Hook
  const navigate = useNavigate();
  const { isAmh } = useLnaguage();

  //Function
  function Logo() {
    return (
      <div
        className="col-start-1 flex flex-row items-center gap-2 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          className="hover:animate-pulse active:animate-nonw w-20"
          src={require("../assets/asset.png")}
        />
        <p className="mx-0 text-xl font-semibold">
          {" "}
          {isAmh ? "ኢትዮ ሳቅ በሳቅ" : "ETHIO SAQ BE SAQ"}
        </p>
      </div>
    );
  }

  //Jsx
  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 py-2 border-t-2 border-blue-100 gap-3">
      <Logo />
      <Wrap
        justify={"center"}
        align="center"
        direction={"row"}
        spacing={{ base: 4, sm: 6 }}
      >
        <Link to="/privacy-policy">
          {" "}
          {isAmh ? "የግላዊነት ፖሊሲ" : "Privacy Policy"}{" "}
        </Link>
      </Wrap>
      <p className="text-xs sm:text-base mb-1">
        &copy; {isAmh ? "2014" : "2022"}{" "}
        {isAmh ? "ኢትዮ ሳቅ በሳቅ" : "ETHIO SAQ BE SAQ"} ||{" "}
        {isAmh ? "ይህ ድህረ-ገፅ የሚንቀሳቀሰው" : "This website is powered by"} Jaktech
      </p>
    </div>
  );
}
