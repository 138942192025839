import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import BreadcrumbComp from "../components/BreadcrumbComp";
import Blog from "../Pages/Blog/Blog";
import Home from "../Pages/Home/Home";
import NotFound from "../Pages/NotFound/NotFound";
import AnnouncemetsBar from "../components/AnnouncemetsBar";
import NavBar from "../components/NavBar";
import PrivacyPolicy from "../utilities/components/PrivacyPolicy";

export default function Auth() {
  //Hook

  //Jsx
  return (
    // bg-gradient-to-tl from-red-200 via-orange-300 to-amber-200
    <div className="bg-gray-50">
      <AnnouncemetsBar />
      <NavBar />
      <BreadcrumbComp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<NotFound />} />{" "}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}
