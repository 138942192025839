import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useLnaguage } from "../context/LnaguageContext";

export default function BreadcrumbComp() {
  //Jsx
  const location = useLocation();
  const navigate = useNavigate();
  const [Blog, setBlog] = useState("");
  const { isAmh } = useLnaguage();

  useEffect(() => {
    setBlog(location?.pathname.split("/")[1]);
  }, [location]);

  //Jsx
  return (
    <div className="pt-4 px-6 md:px-16">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" pb={0.5} />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink
            color={"green.600"}
            fontWeight={"400"}
            fontSize={"14"}
            onClick={() => {
              navigate("/");
            }}
          >
            {isAmh ? "ቤት" : "Home"}
          </BreadcrumbLink>
        </BreadcrumbItem>

        {Blog != "" && (
          <BreadcrumbItem>
            <BreadcrumbLink
              color={"green.600"}
              fontWeight={"400"}
              fontSize={"14"}
              onClick={() => {
                navigate("blog");
              }}
            >
              {isAmh ? "ብሎግ" : "Blog"}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
}
