import { CloseIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useLnaguage } from "../context/LnaguageContext";

export default function AnnouncemetsBar() {
  //Hook
  const [View, setView] = useState(true);
  const { isAmh } = useLnaguage();

  //Jsx
  return (
    <div className="flex flex-row justify-between items-center py-1 px-5 bg-blue-700">
      {View && (
        <>
          <p className="text-white mx-auto text-xs font-extralight">
            {isAmh
              ? "ይህ የማስታወቂያ አሞሌ ለጎብኚዎች ጠቃሚ ነገር ለማሳወቅ ሊያገለግል ይችላል።"
              : "This is an announcement bar can be used to inform visitors of something important"}
          </p>
          <div
            className="mx=0 hover:bg-blue-500 hover:rounded-lg hover:px-2 hover:pb-1"
            onClick={() => setView(false)}
          >
            <CloseIcon boxSize={2} color={"whitesmoke"} />
          </div>
        </>
      )}
    </div>
  );
}
