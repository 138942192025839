import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useLnaguage } from "../../../context/LnaguageContext";
import ReactPlayer from "react-player";

export default function MainView({ data }) {
  //Hook
  const { isAmh } = useLnaguage();

  console.log("sdsa: ", data);

  //Function
  function VideoView() {
    return (
      <ReactPlayer
        playing={true}
        // Disable download button
        config={{
          file: {
            attributes: { controlsList: "nodownload" },
            file: {
              attributes: { preload: "auto" },
              forceAudio: true,
            },
          },
        }}
        // Disable right click
        onContextMenu={(e) => e.preventDefault()}
        // Your props
        url={`${process.env.REACT_APP_BACKEND_FILE_URL}/${data.file}`}
        className="react-player"
        controls
        width="100%"
        height="100%"
      />
    );
  }

  function ImageView() {
    return (
      <img
        className="w-5/6 sm:w-6/6 rounded-sm "
        src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${data.file}`}
      />
    );
  }

  function TextView() {
    return (
      <div className="px-4 py-8 rounded-lg border-2 w-10/12 shadow-sm bg-yellow-50">
        <p className="mx-0 font-extrabold text-gray-900 text-lg sm:text-2xl pb-1">
          <div>
            {ReactHtmlParser(isAmh ? data?.descriptionAm : data?.description)}
          </div>
        </p>
      </div>
    );
  }

  function View() {
    if (data?.file_type == "video") {
      return VideoView();
    } else if (data?.file_type == "image") {
      return ImageView();
    } else if (data?.file_type == "text") {
      return TextView();
    } else {
      return <div />;
    }
  }

  //Jsx
  return (
    <div className="w-11/12 md:w-9/12 flex flex-col items-start gap-4 mt-4 mx-8 md:ml-20 pb-10">
      <p className="mx-0 font-light text-slate-600 ">
        {isAmh ? "የታተመ" : "Published"} {data?.created_at}
      </p>
      <div className="flex flex-col gap-2">
        <p className="mx-0 w-5/6 sm:w-full font-extrabold text-gray-900 text-lg sm:text-2xl pb-1">
          {isAmh ? data?.titleAm : data?.title}
        </p>
        <View />
        <p className="mx-0 font-normal text-md text-gray-500">
          {isAmh ? "የታተመው በ" : "Published by"} - {data?.published_by}
        </p>
      </div>
    </div>
  );
}
