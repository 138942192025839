import { CheckIcon, CloseIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useLnaguage } from "../../context/LnaguageContext";
import { useMain } from "../../context/MainContext";
import BlogCard from "./components/BlogCard";
import Sidebar from "./components/Sidebar";
import Lottie from "lottie-react";
import gradientLottie from "../../assets/gradient-background.json";
import smileLottie from "../../assets/lf30_editor_tuuhcbil.json";

export default function Home() {
  //Hook
  const { BlogLoad, BlogData } = useMain();
  const { isAmh } = useLnaguage();

  //Function
  function HeaderText() {
    return (
      <div className="flex flex-col justify-center items-center w-full pb-10 pt-5 sm:pt-0">
        <div
          className="flex flex-col lg:flex-row justify-center gap-1 lg:gap-10 items-center rounded-lg w-5/6 py-1"
          style={{
            backgroundColor: "#df5828",
            backgroundImage: `linear-gradient(90deg, #df5828 0%, #df5828 46%, #FFCC70 100%)`,
            boxShadow: `0px 10px 55px 30px #DF58283f`,
          }}
        >
          <p className="text-2xl sm:text-3xl font-bold text-white">
            {isAmh ? "እንኳን በደህና ወደ" : "Welcome to"}
          </p>
          <img className="mx-0 w-60" src={require("../../assets/asset.png")} />
          <p className="text-2xl sm:text-3xl font-bold text-white">
            {isAmh ? "የአስቂኝ ቤት መጡ" : "Home of comedy"}
          </p>
        </div>
      </div>
    );
  }

  function SearchBar() {
    return (
      <div className="w-full flex flex-row justify-center pb-7">
        <div className="w-3/4 sm:w-1/2">
          <InputGroup width={"100%"}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
              children={<Search2Icon />}
            />
            <Input placeholder="Enter amount" borderRadius={12} bg={"white"} />
            <InputRightElement
              children={<CloseIcon boxSize={11} color="red.500" />}
            />
          </InputGroup>
        </div>
      </div>
    );
  }

  //Jsx
  return (
    <div className="flex flex-row">
      <Sidebar />
      <div className="flex flex-col w-full">
        <HeaderText />
        <div className="grid grid-cols-1 px-16 py-3 gap-8 md:px-11 lg:px-20 md:grid-cols-2 lg:grid-cols-3 ">
          {BlogLoad ? (
            <Center w={"100%"}>
              <Spinner />
            </Center>
          ) : (
            BlogData?.map((data) => {
              return <BlogCard data={data} />;
            })
          )}
        </div>
      </div>
    </div>
  );
}
