import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const LnaguageContext = createContext();

export function useLnaguage() {
  return useContext(LnaguageContext);
}

export function LnaguageProvider({ children }) {
  //Hook
  const [isAmh, setIsAmh] = useState(false);

  //Function
  const changeLang = useCallback((isAmh) => {
    setIsAmh(isAmh);
    localStorage.setItem(
      "langData",
      JSON.stringify({
        isAmh,
      })
    );
  }, []);

  let lang;

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("langData"));
    console.log({ storedData });
    if (storedData) {
      if (storedData.isAmh) {
        lang = changeLang(true);
      }
    }
  }, [lang]);

  //Return
  return (
    <LnaguageContext.Provider value={{ isAmh, changeLang }}>
      {children}
    </LnaguageContext.Provider>
  );
}
