import {
  Center,
  Divider,
  Spinner,
  Text,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/Auth";
import { useLnaguage } from "../../../context/LnaguageContext";
import { useMain } from "../../../context/MainContext";
import SideButton from "./SideButton";

export default function Sidebar({ setCardsCount }) {
  //Hook

  const { CarLoad, CatId, setCatId, CarData, setFilter, Filter } = useMain();
  const { isAmh } = useLnaguage();
  const [value, setValue] = React.useState("all");

  //Jsx
  return (
    <div
      class="hidden sticky top-5 md:flex shadow-sm rounded-lg ml-2 mt-7 h-full transition duration-500 ease-in-out  transform hover:translate-y-0.5"
      style={{
        boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
        backgroundColor: "#f2c12e",
      }}
    >
      <div class="flex flex-col sm:flex-row sm:justify-around">
        <div class="w-64 h-screen">
          <nav class="mt-5 px-5 ">
            <SideButton
              name="All"
              nameAm="ሁሉም"
              Id={null}
              CatId={CatId}
              setCatId={setCatId}
            />
            {CarLoad ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              CarData?.map((data, index) => {
                return (
                  <SideButton
                    name={data?.name}
                    nameAm={data?.nameAm}
                    Id={data?.id}
                    CatId={CatId}
                    setCatId={setCatId}
                  />
                );
              })
            )}
            <Text> {isAmh ? "ማጣሪያ" : "Filter by"}</Text>
            <Divider />
            <RadioGroup
              mt={4}
              ml={4}
              onChange={(value) => {
                setValue(value);
                setFilter(value);
              }}
              value={value}
            >
              <Stack>
                <Radio value="all" colorScheme="green">
                  {isAmh ? "ሁሉም" : "All"}
                </Radio>
                <Radio value="text" colorScheme="green">
                  {isAmh ? "ጽሑፍ" : "Text"}
                </Radio>
                <Radio value="image" colorScheme="green">
                  {isAmh ? "ምስል" : "Image"}
                </Radio>
                <Radio value="video" colorScheme="green">
                  {isAmh ? "ቪዲዮ" : "Video"}
                </Radio>
              </Stack>
            </RadioGroup>
          </nav>
        </div>
      </div>
    </div>
  );
}

{
  /* 
 <SideButton
              name="Romantic"
              nameAm="የፍቅር"
              Active={Active}
              setActive={setActive}
            />
            <SideButton
              name="Humor"
              nameAm="ቀልድ"
              Active={Active}
              setActive={setActive}
            />
            <SideButton
              name="Manners"
              nameAm="ምግባር"
              Active={Active}
              setActive={setActive}
            />
            <SideButton
              name="Sentimental"
              nameAm="ስሜታዊ"
              Active={Active}
              setActive={setActive}
            />
            <SideButton
              name="Tragic"
              nameAm="አሳዛኝ"
              Active={Active}
              setActive={setActive}
            /> */
}
