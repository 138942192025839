import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Center,
  Divider,
  Spinner,
  Text,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { useLnaguage } from "../context/LnaguageContext";
import { useMain } from "../context/MainContext";

export default function NavBar() {
  //Hook
  const {
    CarLoad,
    CatId,
    setCatId,
    CarData,
    setComedyType,
    setFilter,
    Filter,
  } = useMain();
  const { changeLang, isAmh } = useLnaguage();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [value, setValue] = React.useState("all");

  console.log("ffrere: ", CatId);

  //Function
  function Logo() {
    return (
      <div
        className="col-start-1 flex flex-row items-center gap-2 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          className="hover:animate-pulse w-11 active:animate-none"
          src={require("../assets/asset.png")}
          // src="https://img.icons8.com/stickers/58/000000/theatre-mask.png"
        />
        <p className="mx-0 text-xl font-semibold">
          {isAmh ? "ኢትዮ ሳቅ በ ሳቅ" : "ETHIO SAQ BE SAQ"}
        </p>
      </div>
    );
  }

  function Language() {
    return (
      <div className="flex flex-row">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={
              <img src="https://img.icons8.com/metro/21/000000/google-translate.png" />
            }
            variant="outline"
          />
          <MenuList>
            <MenuOptionGroup
              defaultValue={!isAmh ? "asc" : "desc"}
              title={isAmh ? "Language" : "ቋንቋ"}
              type="radio"
            >
              <MenuDivider />
              <MenuItemOption
                value="asc"
                onClick={() => {
                  changeLang(false);
                }}
              >
                English
              </MenuItemOption>
              <MenuItemOption
                value="desc"
                onClick={() => {
                  changeLang(true);
                }}
              >
                አማርኛ
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </div>
    );
  }

  function DropMenu() {
    return (
      <div className="col-start-3">
        <div className="hidden md:flex fle-row items-center gap-2">
          {/* <Button
            size={"sm"}
            color={"gray.500"}
            variant={"outline"}
            onClick={() => {
              window.open("sms://9722?body=ok");
            }}
          >
            {isAmh ? "ከደንበኝነት ምዝገባ ይውጡ" : "Unsubscribe"}
          </Button>
          <Button
            size={"sm"}
            variant={"outline"}
            color={"gray.500"}
            onClick={() => {
              logout();
            }}
          >
            {isAmh ? "ውጣ" : "Logout"}
          </Button> */}
          <Language />
        </div>
        <div className="flex md:hidden">
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="outline"
                />
                <MenuList w={"100vw"}>
                  <MenuItem
                    // bg={CatId !== null ? "lightgray" : "white"}
                    color={CatId === null ? "blue" : "gray"}
                    onClick={() => setCatId(null)}
                  >
                    {isAmh ? "ሁሉም" : "All"}
                  </MenuItem>
                  {CarData?.map((data) => {
                    return (
                      <MenuItem
                        bg={"#EDF2F7"}
                        color={CatId === data?.id ? "blue" : "gray"}
                        onClick={() => {
                          setCatId(data?.id);
                        }}
                      >
                        {isAmh ? data?.nameAm : data?.name}
                      </MenuItem>
                    );
                  })}
                  <MenuDivider />
                  <Text> {isAmh ? "ማጣሪያ" : "Filter by"}</Text>
                  <Divider />
                  <RadioGroup
                    mt={4}
                    ml={4}
                    onChange={(value) => {
                      setValue(value);
                      setFilter(value);
                    }}
                    value={value}
                  >
                    <Stack>
                      <Radio value="all" colorScheme="green">
                        {isAmh ? "ሁሉም" : "All"}
                      </Radio>
                      <Radio value="text" colorScheme="green">
                        {isAmh ? "ጽሑፍ" : "Text"}
                      </Radio>
                      <Radio value="image" colorScheme="green">
                        {isAmh ? "ምስል" : "Image"}
                      </Radio>
                      <Radio value="video" colorScheme="green">
                        {isAmh ? "ቪዲዮ" : "Video"}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {/* <MenuItem
                    onClick={() => {
                      window.open("sms://9722?body=ok");
                    }}
                  >
                    {isAmh ? "ከደንበኝነት ምዝገባ ይውጡ" : "Unsubscribe"}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logout();
                    }}
                  >
                    {isAmh ? "ውጣ" : "Logout"}
                  </MenuItem> */}
                  <MenuDivider />
                  <MenuOptionGroup
                    defaultValue={!isAmh ? "asc" : "desc"}
                    title={isAmh ? "Language" : "ቋንቋ"}
                    type="radio"
                  >
                    <MenuItemOption
                      value="asc"
                      onClick={() => {
                        changeLang(false);
                      }}
                    >
                      English
                    </MenuItemOption>
                    <MenuItemOption
                      value="desc"
                      onClick={() => {
                        changeLang(true);
                      }}
                    >
                      አማርኛ
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </>
            )}
          </Menu>
        </div>
      </div>
    );
  }

  //Jsx
  return (
    <div className="grid grid-cols-2 justify-center items-center shadow-sm px-8 py-1 min-w-screen">
      <Logo />
      {CarLoad ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <DropMenu />
      )}
    </div>
  );
}
