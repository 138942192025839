import { useToast } from "@chakra-ui/react";
import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  //Hook
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const toast = useToast();

  //Var
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  //Function
  const login = useCallback((token, user) => {
    setToken(token);
    setUser(user);
    localStorage.setItem(
      "authData",
      JSON.stringify({
        token,
        user,
      })
    );
  }, []);

  const logoutApi = useQuery(
    `logoutApi`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}logout`, {
        headers,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: token == null,
      onSuccess: () => {
        toast({
          title: "Logged Out",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
      },
    }
  );

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    localStorage.setItem(
      "authData",
      JSON.stringify({
        token,
        user,
      })
    );
  }, []);

  let auth;

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("authData"));
    console.log({ storedData });
    if (storedData) {
      if (storedData.token) {
        auth = login(storedData.token, storedData.user);
      }
    } else {
      auth = login(null, null);
    }
  }, [auth]);

  //Return
  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
