import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLnaguage } from "../../../context/LnaguageContext";
import { useMain } from "../../../context/MainContext";

export default function SideButton({ name, nameAm, Id, CatId, setCatId }) {
  //Hook
  const { setComedyType } = useMain();
  const { isAmh } = useLnaguage();

  return (
    <div className="w-full">
      {CatId != Id ? (
        <button
          class="hover:text-gray-800 w-full hover:bg-gray-100 py-2 active:text-gray-100 active:bg-gray-800 flex items-center my-6 transition-colors  text-gray-600 rounded-lg"
          onClick={() => {
            setCatId(Id);
          }}
        >
          <span class="mx-4 text-lg font-normal">{isAmh ? nameAm : name}</span>
          <span class="flex-grow text-right"></span>
        </button>
      ) : (
        <button
          class="w-full py-2 flex items-center my-6 transition-colors  text-gray-100  bg-blue-600 rounded-lg hover:shadow-lg"
          style={{
            boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
            backgroundColor: "#020659",
          }}
          onClick={() => {
            setCatId(Id);
          }}
        >
          <span class="mx-4 text-lg font-normal">{isAmh ? nameAm : name}</span>
          <span class="flex-grow text-right"></span>
        </button>
      )}
    </div>
  );
}

const CarzyButton = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  &:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }
  @media (min-width: 768px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
`;
