import {
  Text,
  Box,
  Stack,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  HStack,
} from "@chakra-ui/layout";
import React from "react";

export default function PrivacyPolicy() {
  return (
    <Box px="5%" py="2%">
      <p>
        <strong>PRIVACY NOTICE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Last updated June 03, 2022</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        This privacy notice for Ethio Saq be Saq ("<strong>Company</strong>," "
        <strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"),
        describes how and why we might collect, store, use, and/or share ("
        <strong>process</strong>") your information when you use our services ("
        <strong>Services</strong>"), such as when you:
      </p>
      <ul>
        <li>
          Download and use our mobile application (Ethio Saq be Saq), or any
          other application of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <p>
        <strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice
        will help you understand your privacy rights and choices. If you do not
        agree with our policies and practices, please do not use our Services.
        If you still have any questions or concerns, please contact us at
        contact@ethiosaqbesaq.com.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>SUMMARY OF KEY POINTS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.&nbsp;
          </strong>
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>What personal information do we process?</strong> When you
        visit, use, or navigate our Services, we may process personal
        information depending on how you interact with Ethio Saq be Saq and the
        Services, the choices you make, and the products and features you
        use.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Do we process any sensitive personal information?</strong> We do
        not process sensitive personal information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Do we receive any information from third parties?</strong> We do
        not receive any information from third parties.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>How do we process your information?</strong> We process your
        information to provide, improve, and administer our Services,
        communicate with you, for security and fraud prevention, and to comply
        with law. We may also process your information for other purposes with
        your consent. We process your information only when we have a valid
        legal reason to do so.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          In what situations and with which parties do we share personal
          information?
        </strong>
        We may share information in specific situations and with specific third
        parties.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>How do we keep your information safe?</strong> We have
        organizational and technical processes and procedures in place to
        protect your personal information. However, no electronic transmission
        over the internet or information storage technology can be guaranteed to
        be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorized third parties will not be able to
        defeat our security and improperly collect, access, steal, or modify
        your information.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>What are your rights?</strong> Depending on where you are
        located geographically, the applicable privacy law may mean you have
        certain rights regarding your personal information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>How do I exercise my rights?</strong> The easiest way to
        exercise your rights is by contacting us on contact@ethiosaqbesaq.com.
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>TABLE OF CONTENTS</strong>
      </p>
      <p>&nbsp;</p>
      <p>1. WHAT INFORMATION DO WE COLLECT?</p>
      <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
      <p>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
      <p>4. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
      <p>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
      <p>6. WHAT ARE YOUR PRIVACY RIGHTS?</p>
      <p>7. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
      <p>8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
      <p>9. DO WE MAKE UPDATES TO THIS NOTICE?</p>
      <p>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
      <p>
        11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Personal information you disclose to us</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:&nbsp;</strong>We collect personal information that
          you provide to us.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Personal Information Provided by You.</strong> The personal
        information that we collect depends on the context of your interactions
        with us and the Services, the choices you make, and the products and
        features you use. The personal information we collect may include the
        following:
      </p>
      <ul>
        <li>phone numbers</li>
        <li>passwords</li>
      </ul>
      <p>
        <strong>Sensitive Information.</strong> We do not process sensitive
        information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Application Data.</strong> If you use our application(s), we
        also may collect the following information if you choose to provide us
        with access or permission:
      </p>
      <ul>
        <li>
          <i>Mobile Device Access.</i> We may request access or permission to
          certain features from your mobile device, including your mobile
          device's storage, and other features. If you wish to change our access
          or permissions, you may do so in your device's settings.
        </li>
        <li>
          <i>Push Notifications.</i> We may request to send you push
          notifications regarding your account or certain features of the
          application(s). If you wish to opt out from receiving these types of
          communications, you may turn them off in your device's settings.
        </li>
      </ul>
      <p>
        This information is primarily needed to maintain the security and
        operation of our application(s), for troubleshooting, and for our
        internal analytics and reporting purposes.
      </p>
      <p>&nbsp;</p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:&nbsp;</strong>We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </strong>
      </p>
      <ul>
        <li>
          <strong>
            To facilitate account creation and authentication and otherwise
            manage user accounts.&nbsp;
          </strong>
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order.
        </li>
        <li>
          <strong>
            To deliver and facilitate delivery of services to the user.&nbsp;
          </strong>
          We may process your information to provide you with the requested
          service.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:</strong> We may share information in specific
          situations described in this section and/or with the following third
          parties.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        We may need to share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          <strong>Business Transfers.</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li>
          <strong>Business Partners.</strong> We may share your information with
          our business partners to offer you certain products, services, or
          promotions.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>4. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:&nbsp;</strong>We keep your information for as long
          as necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us to keep your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p>&nbsp;</p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:&nbsp;</strong>We aim to protect your personal
          information through a system of organizational and technical security
          measures.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:</strong>&nbsp; You may review, change, or terminate
          your account at any time.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:&nbsp;
        <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        If you are located in Switzerland, the contact details for the data
        protection authorities are available here:&nbsp;
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
          https://www.edoeb.admin.ch/edoeb/en/home.html
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Withdrawing your consent:</strong> If we are relying on your
        consent to process your personal information, which may be express
        and/or implied consent depending on the applicable law, you have the
        right to withdraw your consent at any time. You can withdraw your
        consent at any time by contacting us by using the contact details
        provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        below.
      </p>
      <p>&nbsp;</p>
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal, nor when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Account Information</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <ul>
        <li>Log in to your account settings and update your user account.</li>
        <li>Contact us using the contact information provided.</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>
      <p>&nbsp;</p>
      <p>
        If you have questions or comments about your privacy rights, you may
        email us at contact@ethiosaqbesaq.com.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>7. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:&nbsp;</strong>Yes, if you are a resident of
          California, you are granted specific rights regarding access to your
          personal information.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>&nbsp;</p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <i>
          <strong>In Short:&nbsp;</strong>Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </i>
      </p>
      <p>&nbsp;</p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        If you have questions or comments about this notice, you may email us at
        contact@ethiosaqbesaq.com or by post to:
      </p>
      <p>&nbsp;</p>
      <p>Ethio Saq be Saq</p>
      <p>Gurd Shola, Bole Sub-city (Addis Ababa)</p>
      <p>Addis Ababa, Bole Sub-city</p>
      <p>Ethiopia</p>
      <p>&nbsp;</p>
      <p>
        <strong>
          11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please email us on
        contact@ethiosaqbesaq.com.
      </p>
      <p>
        <br />
        &nbsp;
      </p>
    </Box>
  );
}
