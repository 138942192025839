import { MainProvider } from "./context/MainContext";
import { useAuth } from "./context/Auth";
import Auth from "./routes/Auth";
import NoAuth from "./routes/NoAuth";
import Footer from "./components/Footer";

function App() {
  //Hook
  const { token } = useAuth();

  //Jsx
  return (
    <div>
      <MainProvider>{<Auth />}</MainProvider>
      <Footer />
    </div>
  );
}

export default App;
