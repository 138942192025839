import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MainView from "./components/MainView";
import { useMain } from "../../context/MainContext";
import { useLnaguage } from "../../context/LnaguageContext";
import { useAuth } from "../../context/Auth";
import { useQuery } from "react-query";
import axios from "axios";
import { Center, Spinner } from "@chakra-ui/react";

export default function Blog() {
  //Hook
  const { state } = useLocation();
  const [Data, setData] = useState(null);
  const { isAmh } = useLnaguage();
  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Bearer ${token}`,
  };

  const blogData = useQuery(
    `blogDataApi${state}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}blogs/${state}/detail`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,

      onSuccess: (res) => {
        console.log(res.data);
      },
      onerror: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ blogData });
  function Recommended() {
    return (
      <div className="flex flex-col w-11/12 md:w-5/12 mx-8 md:mx-0 my-8 gap-8">
        <p className="font-bold text-gray-700 text-2xl sm:text-3xl">
          {isAmh ? "የሚመከር" : "Recommended"}
        </p>
        {blogData?.data?.data?.data?.recommended?.map((data) => {
          return <VideoComp data={data} />;
        })}
      </div>
    );
  }

  function VideoComp({ data }) {
    return (
      <div
        className="flex flex-row gap-2 hover:bg-slate-200"
        onClick={() => {
          setData(data);
        }}
      >
        {/* <video
          className="h-20 rounded-sm"
          src={require(`../../assets/comedy-blog/${data?.file}`)}
        /> */}
        <img
          // className="w-full h-82 object-contain rounded-sm"
          style={{ height: "12vh" }}
          src={
            data.thumbnail !== null
              ? `${process.env.REACT_APP_BACKEND_FILE_URL}/${data.thumbnail}`
              : "https://www.metroactive.com/features/images/02.15.17/open-mic.jpg"
          }
        />
        <div className="flex flex-col">
          <p className="mx-0 font-bold text-gray-900 text-md pb-1">
            {isAmh ? data?.titleAm : data?.title}
          </p>
          <p className="mx-0 font-light text-sm text-slate-600 ">
            {isAmh ? "የታተመ" : "Published"} {data?.created_at}
          </p>
        </div>
      </div>
    );
  }

  //Jsx
  return (
    <div className="flex flex-col md:flex-row items-start min-w-screen">
      {blogData?.isLoading ? (
        <Center w={"50%"}>
          <Spinner />
        </Center>
      ) : (
        <MainView data={Data ? Data : blogData?.data?.data?.data?.blog} />
      )}
      <Recommended />
    </div>
  );
}

const RecommendedData = [
  {
    id: "11",
    file_type: "video",
    title: "ኮሜድያን ምትኩ ፈንቴ ሰውየው የአረቄ ባለሙያ ነው ነቅንቆ ያውቀዋል",
    titleAm: "ኮሜድያን ምትኩ ፈንቴ ሰውየው የአረቄ ባለሙያ ነው ነቅንቆ ያውቀዋል",
    description: "",
    descriptionAm: "",
    file: "video_1.mp4",
    published_by: "ethio saq be saq",
    created_at: "March 7,2022",
  },
  {
    id: "12",
    file_type: "video",
    title: "ኮሜድያን አብይ ሳህሌ የድሃ እና ሃብታም ጎረቤቶች ልዩነቱ",
    titleAm: "ኮሜድያን አብይ ሳህሌ የድሃ እና ሃብታም ጎረቤቶች ልዩነቱ",
    description: "",
    descriptionAm: "",
    file: "video_2.mp4",
    published_by: "ethio saq be saq",
    created_at: "March 7,2022",
  },
  {
    id: "13",
    file_type: "video",
    title: "ቡና አፍሊዋ ቆንጆ ናት እኔና ጓደኞቼ እሷን ፍለጋ ነበር የምንሄደው ምትኩ ፈንቴ",
    titleAm: "ቡና አፍሊዋ ቆንጆ ናት እኔና ጓደኞቼ እሷን ፍለጋ ነበር የምንሄደው ምትኩ ፈንቴ",
    description: "",
    descriptionAm: "",
    file: "video_3.mp4",
    published_by: "ethio saq be saq",
    created_at: "March 7,2022",
  },
  {
    id: "13",
    file_type: "video",
    title: "ዘማች ወታደሩ ማፈንዳት አይችልም የሚችለው ሽሻ ነበር",
    titleAm: "ዘማች ወታደሩ ማፈንዳት አይችልም የሚችለው ሽሻ ነበር",
    description: "",
    descriptionAm: "",
    file: "video_4.mp4",
    published_by: "ethio saq be saq",
    created_at: "March 7,2022",
  },
];
