import { Icon, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLnaguage } from "../../../context/LnaguageContext";

export default function BlogCard({ data }) {
  //Hook
  const navigate = useNavigate();
  const { isAmh } = useLnaguage();

  console.log("data: ", data);

  //Jsx
  return (
    // w-1/5
    <div
      className="flex flex-col gap-2 h-fit hover:border-2 hover:shadow-sm py-1 px-1 hover:cursor-pointer hover:bg-blue-50"
      onClick={() => {
        navigate("/blog", { state: data?.id });
      }}
    >
      <img
        className="w-full h-52 object-contain rounded-sm"
        src={
          data.thumbnail !== null
            ? `${process.env.REACT_APP_BACKEND_FILE_URL}/${data.thumbnail}`
            : data?.file_type === "image"
            ? `${process.env.REACT_APP_BACKEND_FILE_URL}/${data.file}`
            : "https://www.metroactive.com/features/images/02.15.17/open-mic.jpg"
        }
      />
      <div>
        <Text fontSize={17} fontWeight={600} noOfLines={2}>
          {isAmh ? data?.titleAm : data?.title}
        </Text>
        <div className="flex flex-row items-center gap-1">
          <p className="text-slate-500 font-medium text">
            {isAmh ? "ኢትዮ ሳቅ በሳቅ" : "ETHIO SAQ BE SAQ"}
          </p>
          <Icon viewBox="-75 0 500 150" color="saddlebrown">
            <path
              fill="currentColor"
              d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
            />
          </Icon>
          <p className="text-slate-500 font-medium text-sm">
            {data?.created_at}
          </p>
        </div>
      </div>
    </div>
  );
}
