import React from "react";

export default function NotFound() {
  // TODO add lottie
  //Jsx
  return (
    <div className="min-h-screen bg-gray-100 font-bold text-8xl flex justify-center items-center">
      404 page
    </div>
  );
}
