import React, { createContext, useContext, useEffect, useState } from "react";
import { Data } from "../Data";
import { useLnaguage } from "./LnaguageContext";
import { useAuth } from "./Auth";

import axios from "axios";
import { useQuery } from "react-query";

const MainContext = createContext();

export function useMain() {
  return useContext(MainContext);
}

export function MainProvider({ children }) {
  //Hook
  const { isAmh } = useLnaguage();
  const { token } = useAuth();
  const [CatId, setCatId] = useState(null);
  const [BlogId, setBlogId] = useState(null);
  const [Filter, setFilter] = useState(null);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Bearer ${token}`,
  };

  const categoriesData = useQuery(
    `categoriesDataApi`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}categories`, {
        headers,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,

      onSuccess: () => {},
    }
  );
  console.log({ categoriesData });
  const BlogByCategoryData = useQuery(
    `BlogByCategoryDataApi${CatId} ${Filter}`,
    async () =>
      await axios.get(
        CatId === null
          ? Filter !== null
            ? Filter === "all"
              ? `${process.env.REACT_APP_BACKEND_URL}blogs-by-category`
              : `${process.env.REACT_APP_BACKEND_URL}blogs-by-file-type/${Filter}`
            : `${process.env.REACT_APP_BACKEND_URL}blogs-by-category`
          : Filter !== null
          ? Filter === "all"
            ? `${process.env.REACT_APP_BACKEND_URL}blogs-by-category`
            : `${process.env.REACT_APP_BACKEND_URL}blogs-by-file-type/${Filter}`
          : `${process.env.REACT_APP_BACKEND_URL}blogs-by-category/${CatId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: CatId != 0,
      onSuccess: () => {},
    }
  );

  console.log({ categoriesData: categoriesData?.data?.data?.data });
  console.log(
    { BlogByCategoryData: BlogByCategoryData?.data?.data?.data },
    CatId
  );
  console.log({ filter: Filter });
  console.log({ BlogByCategoryData });
  //Return
  return (
    <MainContext.Provider
      value={{
        CatId,
        setCatId,
        BlogId,
        setBlogId,
        Filter,
        setFilter,
        CarLoad: categoriesData?.isLoading,
        CarData: categoriesData?.data?.data?.data,
        BlogLoad: BlogByCategoryData?.isLoading,
        BlogData: BlogByCategoryData?.data?.data?.data,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}
